import { template as template_96c45fd10ee5474f8c87a190095bc0be } from "@ember/template-compiler";
const FKText = template_96c45fd10ee5474f8c87a190095bc0be(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
