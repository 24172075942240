import { template as template_2ed05395ba8b4087bdc8be3f526d9898 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2ed05395ba8b4087bdc8be3f526d9898(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
