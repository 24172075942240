import { template as template_b13f5a456a3a468c87e03d7a9b013bbb } from "@ember/template-compiler";
const FKLabel = template_b13f5a456a3a468c87e03d7a9b013bbb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
