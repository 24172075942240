import { template as template_ff0c5d32ec624f4ba1c3d5b3ff794ede } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_ff0c5d32ec624f4ba1c3d5b3ff794ede(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
