define("discourse/plugins/listenbrainz/discourse/templates/components/listenbrainz-cover-collage", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showCover}}
  <object data="{{this.src}}"
      type="image/svg+xml"
      title="{{this.title}}"
      {{on "load" this.onLoad}}
      style="{{this.style}}"></object>
  {{else}}
  <span/>
  {{/if}}
  
  */
  {
    "id": "zg5PDfwe",
    "block": "[[[41,[30,0,[\"showCover\"]],[[[11,\"object\"],[16,\"data\",[29,[[30,0,[\"src\"]]]]],[16,\"title\",[29,[[30,0,[\"title\"]]]]],[16,5,[30,0,[\"style\"]]],[24,4,\"image/svg+xml\"],[4,[38,2],[\"load\",[30,0,[\"onLoad\"]]],null],[12],[13],[1,\"\\n\"]],[]],[[[10,1],[12],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"object\",\"on\",\"span\"]]",
    "moduleName": "discourse/plugins/listenbrainz/discourse/templates/components/listenbrainz-cover-collage.hbs",
    "isStrictMode": false
  });
});