import { template as template_0547c27351d54550a919354382a4f6d7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0547c27351d54550a919354382a4f6d7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
